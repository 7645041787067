import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
// Connects to data-controller="nav-select"
export default class extends Controller {
  connect() {
  }

  go() {
    const url = this.element.value
    if (url) Turbo.visit(url)
  }
}
