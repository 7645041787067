import { Controller } from "@hotwired/stimulus";
import { easepick } from "@easepick/core";
import { RangePlugin } from "@easepick/range-plugin";
import { LockPlugin } from "@easepick/lock-plugin";
import { AmpPlugin } from "@easepick/amp-plugin";
import { enter, leave } from "../utils/transition-toggle";
import format from "date-fns/format";

// Connects to data-controller="ticket-filter"
export default class extends Controller {
  static targets = ["frame", "form", "rangePicker", "pickerWrapper"];

  connect() {
    this.picker = new easepick.create({
      element: this.rangePickerTarget,
      css: [this.rangePickerTarget.dataset.cssUrl],
      zIndex: 10,
      grid: 2,
      calendars: 2,
      inline: true,
      plugins: [RangePlugin, LockPlugin, AmpPlugin],
      RangePlugin: {
        tooltip: false,
        startDate: parseInt(this.rangePickerTarget.dataset.initialStartDate, 10) * 1000,
        endDate: parseInt(this.rangePickerTarget.dataset.initialEndDate, 10) * 1000,
      },
      LockPlugin: {
        maxDate: new Date(),
      },
      AmpPlugin: {
        resetButton: true,
      },
    });

    this.picker.on("select", () => {
      this.refresh();
    });

    // show picker initially
    const dateRange = this.formTarget.querySelector("select[name=date_range]").value;
    if (dateRange === "custom" && this.pickerHidden) {
      this.togglePicker();
    }
  }

  get pickerHidden() {
    return this.pickerWrapperTarget.hidden;
  }

  togglePicker() {
    if (this.pickerHidden) {
      enter(this.pickerWrapperTarget);
    } else {
      leave(this.pickerWrapperTarget);
    }
  }

  refresh() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const ruleId = this.formTarget.querySelector("select[name=rule_id],input[name=rule_id]").value;
    const ownerId = this.formTarget.querySelector("select[name=owner_id]").value;
    const dateRange = this.formTarget.querySelector("select[name=date_range]").value;
    const timingType = this.formTarget.querySelector("select[name=timing_type]").value;
    const slaStatus = this.formTarget.querySelector("select[name=sla_status]")?.value;

    params.set("rule_id", ruleId);
    params.set("owner_id", ownerId);
    params.set("date_range", dateRange);
    params.set("timing_type", timingType);
    params.set("sla_status", slaStatus);

    if (dateRange == "custom") {
      if (this.pickerHidden) {
        this.togglePicker();
        return; // don't submit form!
      } else {
        const startDate = this.picker.getStartDate();
        const endDate = this.picker.getEndDate();

        params.set("custom_start_date", format(startDate, "yyyy-MM-dd"));
        params.set("custom_end_date", format(endDate, "yyyy-MM-dd"));
      }
    } else {
      if (!this.pickerHidden) {
        this.picker.clear();
        this.togglePicker();
      }
    }

    const newURL = `${url.origin}${url.pathname}?${params}`;
    this.frameTarget.src = newURL;
  }
}
