import "@hotwired/turbo-rails";
import "./controllers";

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

// https://github.com/basecamp/local_time
import LocalTime from "local-time";
LocalTime.start();

// polyfill we use for multiple arguments to classlist.add(...) and friends
import "classlist.js";

import "./utils/submit-loading";

addEventListener("turbo:load", () => {
  const app = document.getElementById("app");
  if (!app) return;

  const scrollTo = app.dataset.scrollTo;

  if (scrollTo && scrollTo.length > 0) {
    setTimeout(() => {
      document.querySelector(scrollTo).scrollIntoView();
    }, 0);
  }

  LocalTime.start();
});
