import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="resolve-threshold-disable"
export default class extends Controller {

  static targets = [ "toggleField", "fields", "toggleLink" ]

  connect() {
    this._update()
  }

  toggle(event) {
    event.preventDefault()
    
    const isEnabled = (this.toggleFieldTarget.value == "true")

    if (isEnabled) {
      this.toggleFieldTarget.value = "false"
    } else {
      this.toggleFieldTarget.value = "true"
    }

    this._update()
  }

  _update() {
    if (this.toggleFieldTarget.value == "true") {
      this.toggleLinkTarget.innerText = "Disable"
      this.fieldsTarget.classList.remove("opacity-25")
    } else {
      this.fieldsTarget.classList.add("opacity-25")
      this.toggleLinkTarget.innerText = "Enable"
    }
  }
}
