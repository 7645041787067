import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="show-exclude-conditions"
export default class extends Controller {
  static targets = ["content", "link"];

  connect() {
    if (this.element.dataset.hasExcludeConditions === "true") {
      this.show();
    }
  }

  show() {
    this.contentTarget.classList.remove("hidden");
    this.linkTarget.classList.add("hidden");
  }
}
