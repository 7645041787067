import { Controller } from "@hotwired/stimulus";

const ENABLED_ICON = "🎁";
const DISABLED_ICON = "💼";

// Connects to data-controller="disable-holidays"
export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.update();
  }

  toggle(e) {
    const { currentTarget } = e;
    const holidayName = currentTarget.dataset.holidayName;

    const disabledHolidays = this.inputTarget.value
      .split(",")
      .map((n) => n.trim())
      .filter((name) => name !== "");

    if (disabledHolidays.includes(holidayName)) {
      disabledHolidays.splice(disabledHolidays.indexOf(holidayName), 1);
    } else {
      disabledHolidays.push(holidayName);
    }

    this.inputTarget.value = disabledHolidays.join(",");

    this.update();
  }

  update() {
    const disabledNames = this.inputTarget.value;

    // reset everything
    this.element.querySelectorAll(".holiday").forEach((holidayEl) => {
      holidayEl.querySelector(".icon").textContent = ENABLED_ICON;
      holidayEl.querySelector(".name").classList.remove("line-through", "group-hover:line-through");
      holidayEl.classList.remove("disabled", "opacity-75");
    });

    disabledNames
      .split(",")
      .map((n) => n.trim())
      .filter((name) => name !== "")
      .forEach((name) => {
        const holidayEl = this.element.querySelector(`[data-holiday-name="${name}"]`);
        holidayEl.querySelector(".icon").textContent = DISABLED_ICON;
        holidayEl.querySelector(".name").classList.add("line-through", "group-hover:line-through");
        holidayEl.classList.add("disabled", "opacity-75");
      });
  }
}
