import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-analytics"
export default class extends Controller {
  connect() {
  }
  
  track() {
    window.plausible(this.element.dataset.analyticsEvent)
  }
}
