import { Controller } from "@hotwired/stimulus";

const UPDATE_INTERVAL = 15 * 1000;

// Connects to data-controller="update-ticket-timings"
export default class extends Controller {
  connect() {
    setInterval(() => {
      this.element.src = window.location.href;
    }, UPDATE_INTERVAL);

    this.element.addEventListener("turbo:frame-missing", (e) => {
      if (rg4js) {
        rg4js("send", {
          error: new Error(`Turbo frame missing, ${window.location.href}: ${this.element.dataset.remoteId}, ${e}`),
        });
      }
      e.preventDefault();
    });
  }
}
