import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="skip-confirm"
export default class extends Controller {

  static targets = ["confirmModal"]

  connect() {
  }

  showModal(event) {
    this.confirmModalTarget.classList.remove("hidden")
    event.preventDefault()
  }
}
