import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition"; // need this rather than useTransition because it doesn't support multiple elements, see: https://github.com/stimulus-use/stimulus-use/issues/160

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["overlay", "body"];

  connect() {
    enter(this.overlayTarget);
    enter(this.bodyTarget);
    document.addEventListener("turbo:submit-end", this.handleSubmit);
  }

  disconnect() {
    document.removeEventListener("turbo:submit-end", this.handleSubmit);
  }

  close(event) {
    if (event) event.preventDefault();
    Promise.all([leave(this.overlayTarget), leave(this.bodyTarget)]).then(() => {
      let container = this.element.closest("[data-modal-container]");
      if (container) {
        container.classList.add("hidden");
        this.bodyTarget.classList.remove("hidden");
        this.overlayTarget.classList.remove("hidden");
      } else {
        this.element.remove();
      }
    });
  }

  escClose(event) {
    const fancySelectOpen = document.querySelectorAll(`[data-fancyselect-target="dropdown"]:not(.hidden)`).length > 0;
    if (event.key === "Escape" && !fancySelectOpen) this.close();
  }

  handleSubmit = (e) => {
    if (e.detail.success) {
      this.close();
    }
  };
}
