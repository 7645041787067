import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="detect-timezone"
export default class extends Controller {

  static targets = ["field"]

  connect() {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.fieldTarget.value = this.timeZone
  }
}
