addEventListener("turbo:submit-start", (event) => {

  const submitButton = event.target.querySelector("input[type=submit]")

  if (submitButton) {
    submitButton.disabled = true
    submitButton.style.width = submitButton.clientWidth + "px"
    submitButton.dataset.originalValue = submitButton.value
    submitButton.value = "…"
    submitButton.classList.add("opacity-50", "cursor-not-allowed", "pointer-events-none")
  }

})

addEventListener("turbo:submit-end", (event) => {
  const submitButton = event.target.querySelector("input[type=submit]")
  if (submitButton && submitButton.dataset.originalValue) {
    submitButton.disabled = false
    submitButton.style.width = ""
    submitButton.value = submitButton.dataset.originalValue
    submitButton.classList.remove("opacity-50", "cursor-not-allowed", "pointer-events-none")
  }

})