import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="avatar-preview"
export default class extends Controller {

  static targets = ["image"]

  connect() {
  }

  fileChosen(event) {
    this.imageTarget.classList.remove("hidden")
    this.fileToDataUrl(event, src => this.imageTarget.src = src)
  }

  fileToDataUrl(event, callback) {
    if (!event.target.files.length) return

    let file = event.target.files[0],
      reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = e => callback(e.target.result)
  }
}
