import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "../utils/transition-toggle";

// Connects to data-controller="alert-form"
export default class extends Controller {
  static targets = ["recipientSelect", "note", "noteTrigger"];

  connect() {
    this.primaryIntegration = this.element.dataset.primaryIntegration;
  }

  checkShouldShowRecipients() {
    if (this.notificationType == "slack") {
      leave(this.recipientSelectTarget);
    } else {
      enter(this.recipientSelectTarget);
    }
  }

  recipientIdsChanged(e) {
    if (e.target.tomselect) {
      const ids = e.target.tomselect.getValue();

      this.ownerRecipient = ids.includes("owner");
    }
  }

  notificationTypeChanged(e) {
    this.notificationType = e.target.value;
    this.checkShouldShowRecipients();
  }

  showNote(e) {
    this.noteTriggerTarget.classList.add("hidden");
    this.noteTarget.classList.remove("hidden");
    e.preventDefault();
    return false;
  }
}
