import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";
import { enter, leave } from "el-transition"; // need this rather than useTransition because it doesn't support multiple elements, see: https://github.com/stimulus-use/stimulus-use/issues/160

// Connects to data-controller="fancyselect"
export default class extends Controller {
  static targets = ["value", "option", "currentOption", "dropdown"];

  isOpen = false;

  connect() {
    useClickOutside(this);

    // this weird line runs the initial getter/setter combo for this value....
    this.selectedValue = this.selectedValue;
  }

  get selectedValue() {
    return this.valueTarget.value;
  }

  set selectedValue(newVal) {
    this.valueTarget.value = newVal;
    let evt = new Event("change");
    this.valueTarget.dispatchEvent(evt);

    this.optionTargets.forEach((s) => (s.querySelector(".selected").hidden = true));

    const selectedOption = this.optionTargets.find((o) => o.dataset.value == this.selectedValue);
    this.currentOptionTarget.innerHTML = selectedOption.querySelector(".content").innerHTML;

    selectedOption.querySelector(".selected").hidden = false;
  }

  close() {
    this.isOpen = false;
    this.dropdownTarget.classList.add("hidden");
  }

  escClose(event) {
    if (event.key === "Escape") {
      this.close();
    }
  }

  moveHighlight(event) {
    if (!this.isOpen) return;

    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      const change = event.key === "ArrowDown" ? 1 : -1;

      let hightlightIndex = this.optionTargets.findIndex((e) => e.dataset.value == this.highlightOption) || 0;
      hightlightIndex = hightlightIndex + change;

      hightlightIndex = Math.min(this.optionTargets.length - 1, hightlightIndex);
      hightlightIndex = Math.max(hightlightIndex, 0);

      this.setHighlighted(this.optionTargets[hightlightIndex].dataset.value);
    }
  }

  chooseOnReturnOrSpace(event) {
    if (!this.isOpen) return;

    if (event.key === " " || event.key == "Enter") {
      this.choose();
      event.stopImmediatePropagation();
      event.preventDefault();
      return false;
    }
  }

  open() {
    this.isOpen = true;
    enter(this.dropdownTarget);
  }

  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  hoverOption(event) {
    this.setHighlighted(event.target.dataset.value);
  }

  leaveOption() {
    this.setHighlighted(null);
  }

  choose() {
    if (!this.highlightOption) return;
    this.selectedValue = this.highlightOption;

    this.close();
  }

  setSelected(val) {}

  setHighlighted(val) {
    this.highlightOption = val;

    this.optionTargets.forEach((e) => {
      if (e.dataset.value == this.highlightOption) {
        e.classList.remove(...e.dataset.notHighlightClass.split(" "));
        e.classList.add(...e.dataset.highlightClass.split(" "));
      } else {
        e.classList.add(...e.dataset.notHighlightClass.split(" "));
        e.classList.remove(...e.dataset.highlightClass.split(" "));
      }
    });
  }
}
