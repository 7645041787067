import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="swap-notification-type"
export default class extends Controller {

  static targets = ["value", "phone", "email"]

  connect() {
    this.valueTarget.addEventListener("change", () => this.update())
    this.update()
  }

  update() {
    const value = this.valueTarget.value

    if (value === "email") {
      this.phoneTarget.classList.add("hidden")
      this.emailTarget.classList.remove("hidden")
    } else {
      this.phoneTarget.classList.remove("hidden")
      this.emailTarget.classList.add("hidden")
    }
  }
}
