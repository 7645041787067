import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ticket-timing"
export default class extends Controller {

  static targets = ["select"]

  connect() {
    this.timingFrame = document.querySelector("turbo-frame#ticket_timings")

    this.selectTarget.addEventListener("change", (e) => {
      this.refreshTimingFrame(e)
    })
  }

  refreshTimingFrame(event) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const value = event.target.value

    params.set("timing_type", value)

    const newURL = `${url.origin}${url.pathname}?${params}`
    this.timingFrame.src = newURL
  }
}
