import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="property-value-field"
export default class extends Controller {
  static targets = ["selectedProperty", "fieldContainer", "condition"];

  connect() {
    this.refresh();
  }

  refresh() {
    const selectedOption = this.selectedPropertyTarget.options[this.selectedPropertyTarget.selectedIndex];
    const fields = Array.from(this.fieldContainerTarget.querySelectorAll("input,select"));

    const conditionSelect = this.conditionTarget.querySelector("select");
    const conditionValue = conditionSelect.options[conditionSelect.selectedIndex].value;

    fields.forEach((f) => {
      f.disabled = true;
      f.classList.add("hidden");
    });

    if (conditionValue === "any" || conditionValue === "none") {
      this.conditionTarget.classList.add("w-1/2");
      this.fieldContainerTarget.classList.add("hidden");
    } else {
      this.conditionTarget.classList.remove("w-1/2");
      this.fieldContainerTarget.classList.remove("hidden");
    }

    const propertyType = selectedOption ? selectedOption.dataset.propertyType : "text";
    const correctTypeField = fields.find((f) => f.dataset.propertyType === propertyType);

    if (correctTypeField) {
      correctTypeField.disabled = false;
      correctTypeField.classList.remove("hidden");

      // do we need to add options to the field, i.e an enumeration
      if (selectedOption.dataset.options) {
        correctTypeField.querySelectorAll("option").forEach((o) => o.remove());
        const options = JSON.parse(selectedOption.dataset.options);

        options.forEach((o) => {
          const optEl = document.createElement("option");
          optEl.text = o[0];
          optEl.value = o[1];
          optEl.selected = o[1] == correctTypeField.dataset.selectedValue;
          correctTypeField.add(optEl, null);
        });
      }
    } else {
      // show the default text field
      let defaultField;
      if ((defaultField = fields.find((f) => f.dataset.defaultField))) {
        defaultField.classList.remove("hidden");
      }
    }
  }
}
