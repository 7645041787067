import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="inline-name-editor"
export default class extends Controller {

  static targets = ["field"]

  connect() {
    this.autosize()
  }

  // ensure the field is big enough for its contents!
  autosize() {
    this.fieldTarget.size = Math.min(Math.max(this.fieldTarget.value.length, 10), 32)
  }

  focus() {
    this.fieldTarget.focus()
  }
}
