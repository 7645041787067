import { Controller } from "@hotwired/stimulus"
import { id } from "date-fns/locale";
import TomSelect from "tom-select"

// Connects to data-controller="multiselect"
export default class extends Controller {

  static targets = [ "select" ]

  connect() {
    const isMultiple = (this.selectTarget.getAttribute("multiple") === "multiple")

    let load;
    const optionsLoadUrl = this.selectTarget.dataset.optionsLoadUrl
    if (optionsLoadUrl) {
      load = async function(query, callback) {
        const response = await fetch(optionsLoadUrl + `&q=${encodeURIComponent(query)}`)
        const json = await response.json() 
        callback(json)
      }
    }

    this.select = new TomSelect(this.selectTarget, {
      allowEmptyOption: false,
      closeAfterSelect: !isMultiple,
      maxItems: isMultiple ? null : 1,
      maxOptions: (load ? 250 : null),
      preload: true,
      loadThrottle: 200,
      valueField: "id",
      labelField: "name",
      searchField: "name",
      sortField: this.selectTarget.dataset.sortField || "name",
      create: false,
      load,
      onItemAdd() {
        if (isMultiple) {
          // need to do this otherwise the search text remains for some reason?
          this.setTextboxValue("")
          this.refreshOptions()        
        } else {
          this.control_input.blur()
        }
      }
    })
  }
  disconnect() {

  }
}
