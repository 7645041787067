import { Controller } from "@hotwired/stimulus";
import intlTelInput from "intl-tel-input/intlTelInputWithUtils";

// Connects to data-controller="phone-input"
export default class extends Controller {
  static targets = ["field"];

  connect() {
    this.telInput = intlTelInput(this.fieldTarget, {
      initialCountry: "auto",
      preferredCountries: ["us", "gb", "au", "nz"],
      formatOnDisplay: true,
      nationalMode: true,
      formatAsYouType: true,
      hiddenInput: () => {
        return {
          phone: this.fieldTarget.name,
        };
      },
      geoIpLookup: function (success) {
        let code = document.querySelector("meta[name='detected_country_code']").content || "us";
        success(code);
      },
    });

    // remove the country input that intl tel input automatically adds...
    const hiddenCountryInput = this.fieldTarget.parentElement.querySelector(`input[name='${this.fieldTarget.name}_country']`);
    if (hiddenCountryInput) hiddenCountryInput.remove();
  }

  disconnect() {
    if (this.telInput) this.telInput.destroy();
  }
}
