import { Controller } from "@hotwired/stimulus"
import { easepick } from "@easepick/core"
import { RangePlugin } from '@easepick/range-plugin';
import { LockPlugin } from '@easepick/lock-plugin';
import { AmpPlugin } from '@easepick/amp-plugin';
import { enter, leave } from '../utils/transition-toggle'
import format from "date-fns/format";


// Connects to data-controller="report-form-filter"
export default class extends Controller {
  
  static targets = ["frame", "filterForm", "exportLink", "rangePicker", "pickerWrapper"]
  
  connect() {
    this.picker = new easepick.create({
      element: this.rangePickerTarget,
      css: [
        this.rangePickerTarget.dataset.cssUrl
      ],
      zIndex: 10,
      grid: 2,
      calendars: 2,
      inline: true,
      plugins: [ RangePlugin, LockPlugin, AmpPlugin ],
      RangePlugin: {
        tooltip: false
      },
      LockPlugin: {
        maxDate: new Date()
      },
      AmpPlugin: {
        resetButton: true
      },
    })

    this.picker.on("select", () => {
      this.refresh()
    })

    // show picker initially
    const dateRange = this.filterFormTarget.querySelector("select[name=date_range]").value
    if (dateRange === "custom" && this.pickerHidden) {
      this.togglePicker()
    }

    this.setExportLinkUrl()
  }

  get pickerHidden() {
    return this.pickerWrapperTarget.hidden
  }

  togglePicker() { 
    if (this.pickerHidden) {
      enter(this.pickerWrapperTarget)
    } else {
      leave(this.pickerWrapperTarget)
    }
  }

  get params() {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    const ruleId = this.filterFormTarget.querySelector("select[name=rule_id]").value
    const ownerId = this.filterFormTarget.querySelector("select[name=owner_id]").value
    const dateRange = this.filterFormTarget.querySelector("select[name=date_range]").value
    const timingType = this.filterFormTarget.querySelector("select[name=timing_type]").value

    params.set("rule_id", ruleId)
    params.set("owner_id", ownerId)
    params.set("date_range", dateRange)
    params.set("timing_type", timingType)
    
    if (dateRange == "custom" && !this.pickerHidden) {
      const startDate = this.picker.getStartDate()
      const endDate = this.picker.getEndDate()

      params.set("custom_start_date", format(startDate, "yyyy-MM-dd"))
      params.set("custom_end_date", format(endDate, "yyyy-MM-dd"))
    }

    return params
  }

  setExportLinkUrl() {
    const exportLink = this.exportLinkTarget
    const exportURL = new URL(exportLink.href)
    const exportURLWithParams = `${exportURL.origin}${exportURL.pathname}?${this.params}`
    exportLink.href = exportURLWithParams
  }
  
  refresh() {        
    const url = new URL(window.location.href)

    if (this.params.get("date_range") == "custom") {
      if (this.pickerHidden) {
        this.togglePicker()
        return // don't submit form!
      }
    } else {
      if (!this.pickerHidden) {
        this.picker.clear()
        this.togglePicker()
      }
    }

    const newURL = `${url.origin}${url.pathname}?${this.params}`
    this.frameTarget.src = newURL
    // window.location.href = newURL

    this.setExportLinkUrl()
  }
}
