import { Controller } from "@hotwired/stimulus"

import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(...registerables);
Chart.register(annotationPlugin);

Chart.defaults.font.family = `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`;

// Connects to data-controller="time-to-action-chart"
export default class extends Controller {
  connect() {

    const timeUnit = this.element.dataset.timeUnit
    const min = this.element.dataset.min
    const max = this.element.dataset.max
    const slaSeconds = this.element.dataset.slaSeconds

    const dataJSON = this.element.dataset.responseTimes
    const data = JSON.parse(dataJSON)

    const ctx = this.element.getContext('2d')
    this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          datasets: [{
            label: 'Time to resolution',
            data,
            backgroundColor: 'rgb(128, 90, 213)',
            hoverBackgroundColor: 'rgb(85, 60, 154)',
            borderWidth: 0,
            barThickness: 6,
            borderRadius: 20,
            borderSkipped: false,
            skipNull: false
          }]
        },
        options: {
          devicePixelRatio: 3, // need because we print!
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                label(context) {
                  let label
                  if (context.parsed.y !== null) {
                    const duration = parseInt(context.parsed.y, 10)
                    const totalMinutes = Math.floor(duration / 60)
                    const hours = Math.floor(totalMinutes / 60)
                    const minutes = totalMinutes % 60
                    if (hours > 0) {
                      label = `${hours} hour${(hours > 1) ? "s" : ""}, ${minutes} minute${(minutes == 1) ? "" : "s"}`
                    } else {
                      label = `${minutes} minute${(minutes == 1) ? "" : "s"}`
                    }
                  }
                  return label
                }
              }
            },
            annotation: {
              annotations: {
                slaLine: {
                  type: 'line',
                  yMin: slaSeconds,
                  yMax: slaSeconds,
                  borderColor: 'rgba(250, 204, 21, 0.5)',
                  borderWidth: (parseInt(slaSeconds, 10) != 0) ? 2 : 0,
                  borderDash: [5, 5],
                  label: {
                    content: "SLA",
                    enabled: (parseInt(slaSeconds, 10) != 0),
                    backgroundColor: 'rgb(249 250 251)',
                    color: 'rgb(31 41 55)',
                    position: 'end'
                  }
                }
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                drawBorder: false,
                drawOnChartArea: false,
                drawTicks: false
              },
              ticks: {
                color: 'rgb(156 163 175)',
                callback: function (value, index, ticks) {
                  return Math.ceil(parseInt(value, 10) / (60 * 60))
                },
                stepSize: 60 * 60,
              },
            },
            x: {
              type: 'time',
              bounds: 'ticks',
              min,
              max,
              offset: true,
              time: {
                unit: timeUnit,
                round: timeUnit,
                tooltipFormat: (timeUnit === 'day') ? 'eee, LLLL do' : 'EEEE do, haaa',
                displayFormats: {
                  hour: 'haaa'
                }                
              },
              grid: {
                drawBorder: false,
                drawOnChartArea: false,
                drawTicks: false
              },
              ticks: {
                minRotation: 90,
                maxRotation: 90,
                padding: 10,
                labelOffset: -7,
                color: 'rgb(156 163 175)'
              }
            }
          }
        }
    })

    addEventListener('beforeprint', (event) => { 
      this.chart.resize()      
    });
  }
}
