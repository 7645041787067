import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="holiday-regions"
export default class extends Controller {

  static targets =  ["regionFrame", "nextHolidaysFrame"]

  connect() {
  }

  load(event) {
    const selectedCountry = this.element.querySelector("select[id$=holidays_country]").value
    let selectedRegion = this.element.querySelector("select[id$=holidays_region]").value

    if (event.target.id == "schedule_holidays_country") {
      selectedRegion = selectedCountry
    }

    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    params.set("holidays_country", selectedCountry)
    params.set("holidays_region", selectedRegion)

    const newURL = `${url.origin}${url.pathname}?${params}`
    this.regionFrameTarget.src = newURL
    this.nextHolidaysFrameTarget.src = newURL
  }

}
