import { Controller } from "@hotwired/stimulus"
import { enter, leave } from '../utils/transition-toggle'

// Connects to data-controller="notification"
export default class extends Controller {
  static targets = ["content"]

  static values = {
    closeTimeout: Number
  }

  connect() {
    enter(this.contentTarget)

    setTimeout(() => {
      this.close()
    }, this.closeTimeoutValue)
  }

  close() {
    leave(this.contentTarget)
  }
}
