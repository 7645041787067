import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="schedule-times"
export default class extends Controller {
  connect() {
    this.update()
  }

  update() {
    // find the disabled rows and apply the disabled classes
    const disabledClassNames = this.element.dataset.disabledClassNames.split(" ")

    this.element.querySelectorAll("tr").forEach(row => {
      const checkbox = row.querySelector(".checkbox input[type=checkbox]")
      const nonCheckboxCells = row.querySelectorAll("td:not(.checkbox)")
      const fields = row.querySelectorAll("input[type=time]")

      if (checkbox.checked) {
        nonCheckboxCells.forEach(f => f.classList.remove(...disabledClassNames))
        fields.forEach(f => f.disabled = false)
      } else {
        nonCheckboxCells.forEach(f => f.classList.add(...disabledClassNames))
        fields.forEach(f => f.disabled = true)
      }

    })
  }
}
