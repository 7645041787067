import { Controller } from "@hotwired/stimulus";

import debounce from "lodash/debounce";

// Connects to data-controller="ticket-preview"
export default class extends Controller {
  static targets = ["frame"];

  connect() {
    this.refresh = debounce(this.refresh, 200).bind(this);
  }

  refresh() {
    const frameSrcWithoutParams = this.frameTarget.src.split(/[?#]/)[0];

    const selects = Array.from(this.element.querySelectorAll("select.id-list-select"));

    let query = selects.reduce((result, el) => {
      let normalisedName = el.name.match(/rule\[([A-z_0-9]+)\]\[\]/)[1] + "[]";

      el.querySelectorAll("option:checked").forEach((o) => {
        result = result.concat(`${normalisedName}=${o.value}&`);
      });

      return result;
    }, "");

    let propertyTargetsFrame = this.element.querySelector(".property-targets-frame");
    if (propertyTargetsFrame) {
      const customPropertyIdFields = propertyTargetsFrame.querySelectorAll("select[id$='custom_property_id']");

      Array.from(customPropertyIdFields).forEach((f) => {
        const selectedCustomProperty = f.querySelector("option:checked");
        if (selectedCustomProperty) {
          const valueFieldName = f.name.replace("[custom_property_id]", "[value]");
          const valueField = propertyTargetsFrame.querySelector(`[name='${valueFieldName}']:enabled`);
          const value = valueField ? valueField.value : null;

          const conditionFieldName = f.name.replace("[custom_property_id]", "[condition]");
          const condition = propertyTargetsFrame.querySelector(`[name='${conditionFieldName}']`).value || "equals";

          query = query.concat(`custom_properties[${selectedCustomProperty.dataset.propertyName}]=${value}&custom_property_condition[${selectedCustomProperty.dataset.propertyName}]=${condition}&`);
        }
      });
    }

    // append the timestamp so that the frame source always changes and Turbo always reloads
    query = query.concat(`ts=${new Date().getTime()}`);

    this.frameTarget.src = `${frameSrcWithoutParams}?${query}`;
  }
}
