import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="auto-submit-checkbox"
export default class extends Controller {
  connect() {}

  sendForm() {
    this.element.requestSubmit();
  }
}
