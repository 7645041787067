import { Controller } from "@hotwired/stimulus"
import { toggle, enter, leave } from '../utils/transition-toggle'

// Connects to data-controller="user-menu"
export default class extends Controller {

  static values = {
    active: Boolean
  }

  static targets = ["menu", "button", "expand", "accountSwitcher"]

  connect() {
  }

  checkForEscKey(event) {
    if (this.menuTarget.hidden) {
      return
    }

    if (event && event.key === "Escape") {
      this.hide()
    }
  }

  hide(event) {
    if (this.menuTarget.hidden) {
      return
    }
    
    if (event && (this.menuTarget.contains(event.target) || this.buttonTarget.contains(event.target))) {
      // this click was on the menu, so we'll allow it
      return
    }

    leave(this.menuTarget)
    this.buttonTarget.classList.remove("bg-gray-200")
  }

  toggle() {
    toggle(this.menuTarget)

    // keep the button looking active
    this.buttonTarget.classList.toggle("bg-gray-200")
  }

  showExpand() {
    if (this.expandTarget.hidden) {
      enter(this.expandTarget)
    }
  }

  hideExpand(e) {
    if (this.expandTarget.hidden) return
    
    if (e.target == this.expandTarget || e.target == this.accountSwitcherTarget || this.expandTarget.contains(e.target) || this.accountSwitcherTarget.contains(e.target)) {
    } else {
      console.log("hiding")
      leave(this.expandTarget)
    }
  }
}
