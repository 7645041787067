import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="switch"
export default class extends Controller {

  static targets = ["field"]

  connect() {
    this.enabled = this.fieldTarget.checked
    this._updateClassNames()
  }

  toggle() {
    this.fieldTarget.checked = !this.fieldTarget.checked
    this.update()
  }

  update() {
    this.enabled = this.fieldTarget.checked 
    this._updateClassNames()  
  }

  _updateClassNames() {
    this.element.querySelectorAll("[data-enabled-classes]").forEach((e) => {

      let enabledClasses = e.dataset.enabledClasses.split(" ")
      let disabledClasses = e.dataset.disabledClasses.split(" ")

      e.classList.remove(...enabledClasses, ...disabledClasses)

      if (this.enabled) {
        e.classList.add(...enabledClasses)
      } else {
        e.classList.add(...disabledClasses)
      }
    })
  }

}
