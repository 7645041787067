import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="repeat-custom-holidays"
export default class extends Controller {
  static targets = ["selector"];

  toggle(e) {
    const el = e.currentTarget;

    const yearSelector = this.selectorTarget.querySelector("select[name*='date(1i)']");

    if (el.checked) {
      yearSelector.classList.add("hidden");
    } else {
      yearSelector.classList.remove("hidden");
    }
  }
}
