import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="direct-upload-progress"
export default class extends Controller {

  static targets = [ "bar" ]

  connect() {
    this.startListener = addEventListener("direct-upload:start", () => this.show())
    this.progressListener = addEventListener("direct-upload:progress", (event) => this.progress(event))
    this.endListener = addEventListener("direct-upload:end", () => this.hide())
  }

  disconnect() {
    removeEventListener("direct-upload:start", this.startListener)
    removeEventListener("direct-upload:progress", this.progressListener)
    removeEventListener("direct-upload:end", this.endListener)
  }

  progress(event) {
    const { _, progress } = event.detail
    this.barTarget.style.width = `${progress}%`
  }

  show() {
    this.element.classList.remove("hidden")
  }

  hide() {
    this.element.classList.add("hidden")
  }
}
